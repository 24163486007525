class LocalStorage {
    _storage;

    constructor(storage: Storage) {
        this._storage = storage;
    }

    set(key: string, value: string | object) {
        if (typeof (value) === 'object') {
            value = JSON.stringify(value);
        }

        this._storage.setItem(key, value);
    }

    get(key: string, defaultValue = null) {
        const value = this._storage.getItem(key);

        if (value === null) {
            return defaultValue;
        }

        try {
            return JSON.parse(value);
        } catch (e) {
            return value;
        }
    }

    remove(key: string) {
        this._storage.removeItem(key);
    }
}

const localStorage = new LocalStorage(window.localStorage);
export default localStorage;
