import React, { useState } from 'react';
import { createContext } from 'use-context-selector';

export const StockContext = createContext<any>(null);

const StocksProvider = ({ children }: { children: any }) => {
    const [state, setState] = useState({});

    return (
        <StockContext.Provider value={[state, setState]}>
            {children}
        </StockContext.Provider>
    );
};

export default StocksProvider;